import React from 'react'
import * as S from '../style'
import { orange } from 'src/styles/colors'
import IcClose from '@interco/icons/core/action-navigation/ic_close'
import usePageQuery from '../pageQuery'
import Img from 'gatsby-image'

type ExistingClientViewProps = {
  closeModal: () => void;
}

export const ExistingClientView = ({
  closeModal,
}: ExistingClientViewProps) => {
  const data = usePageQuery()
  return (
    <S.Container isModal className='d-flex align-items-center qrcode'>
      <S.CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
        <IcClose width='24' height='24' color={orange.extra} />
      </S.CloseButton>
      <div className='col-12'>
        <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--400 fw-600'>
          Recebemos os seus dados.
        </p>
        <h3 className='col-10 fs-24 lh-28 text-grayscale--500 pl-0 mb-4 fw-600'>
          <span className='text-orange--extra'> Acesse o app </span>
          e comece a investir e operar no mercado futuro.
        </h3>
        <S.QRCodeDiv className='d-none d-md-flex justify-content-center'>
          <Img fluid={data.qrCodeExistingClient.fluid} alt='QR code para o app do Inter' />
        </S.QRCodeDiv>
        <div className='col-12 d-none d-md-block'>
          <hr />
          <p className='fs-14 lh-17 text-left'>
            Abra a câmera do seu celular e aponte para o QR Code acima.
            Você irá direto pra área de investimentos do app. É rápido e fácil!
          </p>
        </div>
      </div>
    </S.Container>
  )
}
