import React from 'react'
import Img from 'gatsby-image'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import BreadCrumb from 'src/components/Breadcrumb'
import OpenVideo from 'src/components/OpenVideo'

import * as S from './styles'
import usePageQuery from '../../pageQuery'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import {ONE_LINK_URL} from '../../_constants'

type HeroProps = {
  onCtaClick: () => void;
}

const Hero = ({ onCtaClick }: HeroProps) => {
  const data = usePageQuery()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    onCtaClick()
    sendDatalayerEvent({
      section: 'dobra_01',
      element_action: 'click button',
      element_name: ' Operar agora',
      section_name: 'Corretagem zero para operar minicontratos com RLP ativo',
    })
  }
  if (width < WIDTH_MD) return <Hero.SMView />

  return (
    <S.ContentHeader>
      <div className='container'>
        <div className='row py-5'>
          <div className='col-md-6'>
            <BreadCrumb
              sectionName='Global Account Business - Sua conta internacional PJ'
              type={<OrangeIcon color='#6A6C72' size='MD' icon='home' className='mr-1' />}
              product='Investimentos'
              text='Mercado Futuro'
              link='/pra-voce/investimentos/'
            />
            <h1 className='fs-32 fs-lg-40 fs-xl-48 lh-33 lh-lg-50 text-grayscale--500 my-3 my-xl-4 fw-700'>
              <span className='d-block'>Mercado Futuro:</span><span className='d-lg-block'>potencialize seus</span> <span className='d-lg-block'>ganhos como Day</span> Trader
            </h1>
            <p className='fs-14 fs-lg-16 lh-17 lh-lg-19 text-grayscale--400 fw-400'>
              <span className='d-lg-block'>Maximize seu sucesso como Day Trader operando</span> <span className='d-lg-block'>minicontratos de forma eficiente e com corretagem</span> zero através do RLP ativo.
            </p>
            <button
              className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
              onClick={handleClick}
            >
              Operar agora
            </button>
          </div>
          <div className='col-md-6 p-0'>
            <OpenVideo
              styles='open-video'
              link='https://www.youtube.com/embed/cx5OcPJTcKw?&autoplay=1'
              onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_01',
                element_action: 'click button',
                element_name: 'null',
                section_name: 'Corretagem zero para operar minicontratos com RLP ativo',
              })
              }}
            >
              <S.ButtonVideo>
                <Img fluid={data.PlayMercadoFuturo.fluid} alt='Assista ao vídeo' />
              </S.ButtonVideo>
            </OpenVideo>
          </div>
        </div>
      </div>
    </S.ContentHeader>
  )
}

Hero.SMView = function SMView () {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const handleClick = () => {
    sendDatalayerEvent({
      section: 'dobra_01',
      element_action: 'click button',
      element_name: ' Operar agora',
      section_name: 'Corretagem zero para operar minicontratos com RLP ativo',
    })
  }
  return (
    <>
      <div className='container p-0'>
        <div className='row'>
          <div className='col-12'>
            <S.ContentHeader>
              <OpenVideo
                styles='open-video'
                link='https://www.youtube.com/embed/cx5OcPJTcKw'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click video',
                    element_name: '',
                    section_name: 'Corretagem zero para operar minicontratos com RLP ativo',
                  })
                }}
              >
                <S.ButtonVideo>
                  <Img fluid={data.PlayMercadoFuturo.fluid} alt='Assista ao vídeo' />
                </S.ButtonVideo>
              </OpenVideo>
            </S.ContentHeader>
          </div>

        </div>
      </div>
      <div className='container py-4'>
        <div className='row'>
          <div className='col-12'>
            <BreadCrumb
              sectionName='Global Account Business - Sua conta internacional PJ'
              type={<OrangeIcon color='#6A6C72' size='MD' icon='home' className='mr-1' />}
              product='Investimentos'
              text='Mercado Futuro'
              link='/pra-voce/investimentos/'
            />
            <h1 className='fs-32 fs-lg-40 fs-xl-48 lh-33 lh-lg-50 text-grayscale--500 my-3 my-xl-4 fw-700'>
              <span className='d-block'>Mercado Futuro:</span><span className='d-lg-block'>potencialize seus</span> <span className='d-lg-block'>ganhos como Day</span> Trader
            </h1>
            <p className='fs-14 fs-lg-16 lh-17 lh-lg-19 text-grayscale--400 fw-400'>
              <span className='d-lg-block'>Maximize seu sucesso como Day Trader operando</span> <span className='d-lg-block'>minicontratos de forma eficiente e com corretagem</span> zero através do RLP ativo.
            </p>
            <a
              href={ONE_LINK_URL}
              className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
              onClick={handleClick}
            >
              Operar agora
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero
