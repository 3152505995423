import React from 'react'
import * as S from '../style'
import IcClose from '@interco/icons/core/action-navigation/ic_close'
import { orange } from 'src/styles/colors'
import errorImg from 'src/assets/images/shared/erro-pig.png'

type FormErrorProps = {
  closeModal: () => void;
  handleReturn: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const FormError = ({ closeModal, handleReturn }: FormErrorProps) => {
  return (
    <S.Container isModal className='d-flex align-items-center'>
      <S.CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
        <IcClose width='24' height='24' color={orange.extra} />
      </S.CloseButton>
      <div className='col-12 text-center'>
        <h3 className='fs-20 lh-24 mb-5'>Ah não! Ocorreu um erro com a sua solicitação...</h3>
        <img src={errorImg} alt='Imagem de erro' />
        <p className='text-grayscale--400 fs-18 lh-22 pt-3'>
          Verifique a sua conexão com a internet e tente novamente. <br />
          <button onClick={handleReturn} title='Clique aqui para voltar' className='bg-transparent border-0 fw-600 pr-1'>
            Clique aqui
          </button>
          para voltar.
        </p>
      </div>
    </S.Container>
  )
}
