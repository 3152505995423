import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      qrCodeExistingClient: imageSharp(fluid: { originalName: { regex: "/qr-code-mercado-futuro/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      qrContaKids: imageSharp(fluid: { originalName: { regex: "/conta-kids-mercado-futuro-qr-code/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
