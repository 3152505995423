import React, { useState } from 'react'
import { Modal } from 'src/components/Modal'
import Layout from 'src/layouts/BaseLayout'

import useDomReady from 'src/hooks/window/useDomReady'

import MercadoFuturoRightForm from './components/MercadoFuturoRightForm/_index'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import AindaNaoInveste from './sections/AindaNaoInveste/_index'
import ComoFuncionaMercadoFuturo from './sections/ComoFuncionaMercadoFuturo/_index'
import ConfiraMargensReduzidas from './sections/ConfiraMargensReduzidas/_index'
import ConfiraQuandoNegociar from './sections/ConfiraQuandoNegociar/_index'
import ContratoFuturo from './sections/ContratoFuturo/_index'
import FaqMercadoFuturo from './sections/FaqMercadoFuturo/_index'
import InvestPro from './sections/InvestPro/_index'
import MelhoresBeneficiosSuperApp from './sections/MelhoresBeneficiosSuperApp/_index'
import MelhoresFerramentasInvestPro from './sections/MelhoresFerramentasInvestPro/_index'
import ValoresMercadoFuturo from './sections/ValoresMercadoFuturo/_index'
import Hero from './sections/MercadoFuturoHero/_index'
import { ExistingClientView } from './components/MercadoFuturoRightForm/views/_ExistingClientView'

const MercadoFuturo = () => {
  const [ isOpenAccountModalOpen, setIsOpenAccountModalOpen ] = useState(false)
  const [ isExistingClientModalOpen, setIsExistingClientModalOpen ] = useState(false)

  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_14',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  const showOpenAccountModal = () => { setIsOpenAccountModalOpen(true) }
  const hideOpenAccountModal = () => { setIsOpenAccountModalOpen(false) }

  const openAccountForm = domReady && (
    <Modal isModalOpen={isOpenAccountModalOpen} setIsModalOpen={setIsOpenAccountModalOpen} locationToRender={document.body}>
      <MercadoFuturoRightForm
        onExistingClientClick={() => {
          hideOpenAccountModal()
          setIsExistingClientModalOpen(true)
        }}
        closeModal={() => setIsOpenAccountModalOpen(false)}
      />
    </Modal>
  )

  const existingClientModal = domReady && (
    <Modal
      isModalOpen={isExistingClientModalOpen}
      setIsModalOpen={setIsExistingClientModalOpen}
      locationToRender={document.body}
    >
      <ExistingClientView closeModal={() => setIsExistingClientModalOpen(false)} />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountForm}
        {existingClientModal}
        <Hero onCtaClick={showOpenAccountModal} />
        <ContratoFuturo onCtaClick={showOpenAccountModal} />
        <MelhoresBeneficiosSuperApp />
        <ComoFuncionaMercadoFuturo onCtaClick={showOpenAccountModal} />
        <ValoresMercadoFuturo />
        <MelhoresFerramentasInvestPro />
        <InvestPro />
        <ConfiraQuandoNegociar />
        <ConfiraMargensReduzidas />
        <AindaNaoInveste onCtaClick={showOpenAccountModal} />
        <FaqMercadoFuturo faq={pageContext.structuredData.faq} />
        <DisclaimerInterInvest
          sendDatalayerEvent={sendDatalayerEvent}
          dataLayer={dataLayer}
          operationalLink='https://inter.co/inter-dtvm/'
          section='dobra_12'
        />
      </Layout>
    </Wrapper>
  )
}

export default MercadoFuturo
