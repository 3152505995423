import React, { useState, useEffect, ChangeEvent } from 'react'
import axios from 'axios'

import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import useWidth from 'src/hooks/window/useWidth'

import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'

import { Container, CloseButton } from './style'
import { sendDataLayerEvent, getParameterByName, sendCDPFormData } from 'src/shared/helpers'
import { FormError } from './views/_FormError'
import { FormSuccess } from './views/_FormSuccess'
import { UnderAgeClientView } from './views/_UnderAgeClientView'
import { WIDTH_SM } from 'src/styles/breakpoints'
import { calculateUnderAge } from './helpers/_calculateUnderAge'
import { ONE_LINK_URL } from '../../_constants'

type MercadoFuturoRightFormProps = {
  closeModal: () => void;
  onExistingClientClick: () => void;
  customOrigin?: string;
}
interface IFormValues {
  nome: string;
  email: string;
  celular: string;
  cpf: string;
  dataNascimento: string;
}

const MercadoFuturoRightForm = (props: MercadoFuturoRightFormProps) => {
  const {
    closeModal,
    customOrigin,
    onExistingClientClick,
  }: MercadoFuturoRightFormProps = props
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const windowWidth = useWidth(200)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isSent, setIsSent ] = useState(false)
  const [ isUnderAgeClient, setIsUnderAgeClient ] = useState(false)
  const [ hasError, setHasError ] = useState(false)
  const [ isAccepted, setIsAccepted ] = useState(false)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const [ sendDatalayerEvent ] = useDataLayer()

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setIsLoading(true)
    const isUserUnderAge = calculateUnderAge(data.dataNascimento)
    if (isUserUnderAge) setIsUnderAgeClient(true)

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      dataNascimento: data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      termoAceito: isAccepted,
      origin: customOrigin || 'site-institucional',
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    try {
      if (!isUserUnderAge) {
        await axios.put(`${URLS.OPEN_ACCOUNT_V2}`, formData)
        setIsSent(true)
        sendDataLayerEvent('form_submitted')
        sendDataLayerEvent({
          section: 'm_0',
          element_action: 'submit',
          element_name: 'Abra sua conta digital e invista no mercado futuro hoje mesmo',
        })
        sendCDPFormData({ formName: 'Formulario de investimentos', cpf: formData.cpf, email: formData.email })
      }
    } catch (e) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleReturn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setHasError(false)
    setIsSent(false)
    setIsLoading(false)
  }

  const handleExistingClientClick = () => {
    onExistingClientClick()
    sendDataLayerEvent({
      section: 'm_0',
      element_action: 'submit',
      element_name: 'Abra sua conta digital e invista no mercado futuro hoje mesmo',
    })
  }

  return (
    <>
      { hasError && (<FormError closeModal={closeModal} handleReturn={handleReturn} />)}
      { isSent && (<FormSuccess closeModal={closeModal} />)}
      { isUnderAgeClient && (<UnderAgeClientView closeModal={closeModal} />)}
      <Container isModal className={`${hasError || isSent || isUnderAgeClient ? 'd-none' : 'd-flex'} align-items-center`}>
        <CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
          <Close width='24' height='24' color='orange--extra' />
        </CloseButton>
        <div className='row'>
          <div className='col-11 col-md-10 py-md-4 pl-md-4 pt-lg-0'>
            <h2 className='fs-24 fs-md-32 lh-30 lh-md-40 mb-2 mt-5 text-grayscale--500'>
              Abra sua conta digital e invista no mercado futuro hoje mesmo
            </h2>
          </div>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='nome' className='fs-12 lh-15 fw-600'>Nome</label>
                  <input
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome'
                  />
                  {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='celular' className='fs-12 lh-15 fw-600'>Telefone</label>
                  <input
                    ref={register({
                      required: 'Digite um telefone válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name='celular'
                    id='celular'
                    type='tel'
                    placeholder='Digite seu telefone'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  {errors.celular && <p className='fs-12 text-red--base mb-0 text-right'>{errors.celular.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='email' className='fs-12 lh-15 fw-600'>E-mail</label>
                  <input
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='cpf' className='fs-12 lh-15 fw-600'>CPF</label>
                  <input
                    ref={register({
                      required: 'Digite um CPF válido',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='tel'
                    placeholder='Digite seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors.cpf && <p className='fs-12 text-red--base mb-0 text-right'>{errors.cpf.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 pb-1'>
                  <label htmlFor='dataNascimento' className='fs-12 lh-15 fw-600'>Data de Nascimento</label>
                  <input
                    ref={register({
                      required: 'Digite uma data válida',
                      validate: {
                        isDate: (value: string) => Validations.dateValid(value) || 'Data Inválida',
                      },
                    })}
                    name='dataNascimento'
                    id='dataNascimento'
                    type='tel'
                    placeholder='dd/mm/aaaa'
                    maxLength={10}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('dataNascimento', Masks.MaskDATE(event.currentTarget.value))}
                  />
                  {errors.dataNascimento && <p className='fs-12 text-red--base mb-0 text-right'>{errors.dataNascimento.message}</p>}
                </div>
                <div className='col-12 text-center'>
                  <div className='col-12 text-left d-flex flex-column px-0 pr-md-3'>
                    <AcceptTerms accept={isAccepted} setAccept={setIsAccepted} name='conta-digital-pf' />
                  </div>
                  <div className='row align-items-center justify-content-center'>
                    <div className='col-md-6'>
                      {windowWidth <= WIDTH_SM
                      ? (
                        <a
                          href={ONE_LINK_URL}
                          className='btn bdr btn-white text-orange--extra mb-4 mb-md-0 fs-14 fw-700 rounded-2 mx-auto'
                          onClick={() => {
                          sendDatalayerEvent({
                            section: 'm_0',
                            element_action: 'click button',
                            element_name: 'Já sou cliente Inter',
                            section_name: 'Com a nossa assessoria, você chega mais longe',
                            redirect_url: ONE_LINK_URL,
                          })
                          }}
                        >
                          Já sou cliente Inter
                        </a>
                      ) : (
                        <button
                          type='button'
                          onClick={handleExistingClientClick}
                          className='btn bdr btn-white text-orange--extra mb-4 mb-md-0 fs-14 fw-700 rounded-2 mx-auto'
                        >
                          Já sou cliente Inter
                        </button>)
                      }
                    </div>
                    <div className='col-md-6'>
                      <button
                        type='submit' title='Enviar contato' disabled={!isAccepted || isLoading}
                        className='btn btn-orange--extra fs-14 fw-700 rounded-2 mx-auto text-white text-none'
                      >
                        {isLoading ? 'Enviando...' : 'Continuar'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default MercadoFuturoRightForm
