import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

// Images
import bgImageMobile from './../../assets/images/bg-mercado-futuro-hero-mobile.png'
import bgImageMd from './../../assets/images/bg-mercado-futuro-hero-md.png'
import bgImageLg from './../../assets/images/bg-mercado-futuro-hero-lg.png'
import bgImageXl from './../../assets/images/bg-mercado-futuro-hero-xl.png'

export const ContentHeader = styled.section`
  background-image: url(${bgImageMobile});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;

  h1 {
    font-family: 'Sora', sans-serif !important;
  }

  @media ${device.tablet} {
    background-image: url(${bgImageMd});
    background-repeat: no-repeat;
    background-position: center right;
  }

  @media ${device.desktopLG} {
    min-height: 533px;
    background-image: url(${bgImageLg});
    background-repeat: no-repeat;
    background-position: -92px 0;
  }

  @media ${device.desktopXL} {
    background-image: url(${bgImageXl});
    background-repeat: no-repeat;
    background-position: center right;
    padding-top: 76px;
  }

  .open-video {
    min-height: inherit;
    position: relative;
    @media ${device.tablet} {
      min-height: 100%;
    }
  }

  .btn {
     max-width: 457px;
  }

  p {
    margin-bottom: 32px;
  }

  .bread-crumb {
    span {
      color: ${grayscale[400]} !important;

      a {
      color: ${grayscale[400]} !important;
      font-weight: 900;

        &:hover {
          color: ${grayscale[400]} !important;
        }
      }
    }
  }
 
`
export const ButtonVideo = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  background: transparent;
  border: none;
  outline: none !important;
`
