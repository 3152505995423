import React from 'react'

import Img from 'gatsby-image'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import usePageQuery from '../../pageQuery'

import { Section } from './style'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_SM } from 'src/styles/breakpoints'
import { ONE_LINK_URL } from '../../_constants'

type ComoFuncionaMercadoFuturoProps = {
  onCtaClick: () => void;
}

const ComoFuncionaMercadoFuturo = ({ onCtaClick }: ComoFuncionaMercadoFuturoProps) => {
  const data = usePageQuery()
  const width = useWidth()
  const [sendDatalayerEvent] = useDataLayer()
  const handleClick = () => {
    if (width > WIDTH_SM) {
      onCtaClick()
    }
    sendDatalayerEvent({
      section: 'dobra_03',
      element_action: 'click button',
      element_name: 'Operar no mercado futuro',
      section_name: 'Como funciona o mercado futuro',
    })
  }

  return (
    <Section className='py-5 d-flex align-items-xl-center'>
      <div className='container py-xl-4'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-2 pl-md-4 pl-lg-2 order-md-last'>
            <Img fluid={data.imgMercadoFuturomulher.fluid} alt='Aperto de mãos' />
          </div>
          <div className='col-12 col-md-6 col-lg-6 col-xl-5 pr-0 mt-4 mt-md-0'>
            <h2 className='fs-24 fs-lg-40 lh-30 lh-lg-50 text-grayscale--500 mb-3 pb-1 fw-700'>Como funciona o mercado futuro</h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-lg-19 lh-xl-22 lh-17 text-grayscale--400 mb-5 mb-md-4'>Na Bolsa de Mercadorias e Futuros (BM&F),
              parte da Bolsa de Valores Brasileira (B3), são negociados os contratos futuros, de compra e venda de algum produto, como moedas,
              commodities, índice e outros ativos, com vencimento e quantidades já pré-definidas. Por ser um mercado altamente volátil, é mais indicado para investidores arrojados.
            </p>
            {width <= WIDTH_SM && (
              <a
                href={ONE_LINK_URL}
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
                onClick={handleClick}
              >
                Operar no mercado futuro
              </a>
            )}
            {width > WIDTH_SM && (
              <button
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
                onClick={handleClick}
              >
                Operar no mercado futuro
              </button>
            )}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoFuncionaMercadoFuturo
