import React from 'react'
import * as S from '../style'
import IcClose from '@interco/icons/core/action-navigation/ic_close'
import { orange } from 'src/styles/colors'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import sucesso from '../../../../../../../components/UI/Forms/images/sucesso.png'
import usePageQuery from '../pageQuery'
import Img from 'gatsby-image'
import { ONE_LINK_URL } from '../../../_constants'

type UnderAgeClientViewProps = {
  closeModal: () => void;
}

export const UnderAgeClientView = ({ closeModal }: UnderAgeClientViewProps) => {
  const width = useWidth()
  const data = usePageQuery()
  const url = width < WIDTH_MD ? ONE_LINK_URL : '/pra-voce/conta-digital/conta-kids/'
  return (
    <S.Container isModal className='d-flex align-items-center under-age'>
      <S.CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
        <IcClose width='24' height='24' color={orange.extra} />
      </S.CloseButton>
      <div className='col-12 text-md-center text-lg-left'>
        <h3 className='fs-16 lh-20 text-grayscale--400 fw-400 fw-md-600'>
          Vimos que você tem menos de 18 anos.
        </h3>
        <p className='fs-20 lh-23 fs-md-24 lh-md-28 text-grayscale--500 mb-4 fw-600'>
          <span className='d-block'>
            Conheça nossa&nbsp;
            <a
              className='text-orange--extra text-underline'
              href={url}
            >Conta Kids
            </a>,
          </span>
          que também é completa, digital e gratuita.
        </p>
        <div className='col-12 pb-2 mx-auto d-lg-none text-center'>
          <img src={sucesso} alt='Sucesso' />
        </div>
        <S.QRCodeDiv className='d-none d-md-flex justify-content-center'>
          <Img fluid={data.qrContaKids.fluid} alt='QR code para o app do Inter' />
        </S.QRCodeDiv>
        <div className='col-12 d-none d-lg-block px-0'>
          <hr />
          <p className='fs-14 lh-17 text-left'>
            Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e
            clicar em “Abrir conta completa e gratuita”. É rápido e fácil!
          </p>
          <p className='fs-14 lh-17 text-left'>
            De acordo com nossa Política de Privacidade, não armazenamos dados de menores de 18 anos
            sem o consentimento dos responsáveis.
          </p>
        </div>
      </div>
    </S.Container>
  )
}
