import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

import { Section } from './style'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_SM } from 'src/styles/breakpoints'
import { ONE_LINK_URL } from '../../_constants'

type ContratoFuturoProps = {
  onCtaClick: () => void;
}

const ContratoFuturo = ({ onCtaClick }: ContratoFuturoProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()
  const handleClick = () => {
    if (width > WIDTH_SM) {
      onCtaClick()
    }
    sendDatalayerEvent({
      section: 'dobra_02',
      element_action: 'click button',
      element_name: ' Saiba mais',
      section_name: 'Opere com contrato futuro de Bitcoin',
    })
  }

  return (
    <Section className='py-5 bg-grayscale--100 d-flex align-items-xl-center'>
      <div className='container py-xl-3'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 px-md-0 d-flex justify-content-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/mercado-futuro-mulher/image.webp'
              altDescription=''
              arrayNumbers={[ 312, 336, 376, 456 ]}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-6 offset-lg-1 offset-xl-2 col-xl-5 mt-3'>
            <h2 className='fs-24 fs-lg-40 lh-30 lh-lg-50 text-grayscale--500 mb-3 pb-1 font-citrina fw-700'>Opere com contrato futuro de Bitcoin</h2>
            <p className='fs-14 fs-lg-18 lh-lg-22 lh-17 text-grayscale--400 mb-4'>O contrato futuro de Bitcoin acompanhará o índice Nasdaq Bitcoin Reference Price (NQBTC) e o investidor poderá comprar e vender da mesma forma que está acostumado com outros ativos da BMF.</p>
            <p className='fs-14 fs-lg-18 lh-lg-22 lh-17 text-grayscale--400 mb-4'>Operando um lote com equivalência de 10% do seu valor, com vencimentos mensais. Indicado para investidores arrojados.</p>
            {width <= WIDTH_SM && (
              <a
                href={ONE_LINK_URL}
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 mt-4 text-none'
                onClick={handleClick}>
                Saiba mais
              </a>
            )}
            {width > WIDTH_SM && (
              <button
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 mt-4 mt-xl-5 text-none'
                onClick={handleClick}>
                Saiba mais
              </button>
            )}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ContratoFuturo
